import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDisclosure, Box, Text, Link, Button, Flex, Divider, Alert, AlertIcon, AlertTitle, AlertDescription, Heading, SimpleGrid, Modal, ModalOverlay, ModalContent, ModalHeader,
ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons'; // Import TimeIcon
import Navbar from '../Components/Navbar';
import CustomSpinner from '../Components/CustomSpinner';
import PaymentCard from '../Components/PaymentCard';
import { useAuth } from '../Components/auth/AuthProvider';

function Home() {
  const [depositedMinusExpected, setDepositedMinusExpected] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [showAlert, setShowAlert] = useState(true); // Set showAlert to true initially
  const { user, token } = useAuth();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen : WhatsAppModalisOpen, 
    onOpen : WhatsAppModalonOpen, 
    onClose : WhatsAppModalonClose 

  } = useDisclosure();
  const [AppFee, showAppFee] = useState(false);
  const [dueDate, setDueDate] = useState('');
  const [AppFeeValue, setAppFeeValue] = useState(0);
  const [universityId, setUniversityId] = useState(0); 
  const [WhatsAppGroupAlert, setWhatsAppGroupAlert] = useState(false);

  const whatsapp_group = ["https://chat.whatsapp.com/GiH1erP8ueqIsy1x6U5CuZ","https://chat.whatsapp.com/CVdaJDkMEaB46MxQm42icG", "https://chat.whatsapp.com/BjORK7bhWbf3kCQv4fXm2L"]

  const config = useMemo(() => ({ 
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }), [token]);

  const formattedDate = dueDate => {
    const date = new Date(dueDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BACKEND_URL;

    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    const formattedDueDate = formattedDate(nextWeek);

    // Set due date in state
    setDueDate(formattedDueDate);

    const fetchFinancialData = async () => {
      try {
        const financeResponse = await axios.get(`${baseUrl}/applications/${user}/finances`, config);
        const user_response = await axios.get(`${baseUrl}/applications/${user}`, config);
        const depositedMinusExpectedValue = financeResponse.data.deposited_minus_expected;
        setDepositedMinusExpected(depositedMinusExpectedValue);
        setShowAlert(depositedMinusExpectedValue < 0);
        setUniversityId(user_response.data.university.id);


        // Show App Fee Alert
        if (financeResponse.data.application_fee && user_response.data.status !== 'pending') {
            setAppFeeValue(financeResponse.data.application_fee);
            showAppFee(true);
        }

        const date_applied = new Date(user_response.data.date_applied) 
        const new_date = new Date() 
        const diffTime = new_date - date_applied;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        if(user_response.data.status === 'approved' && diffDays > 30) {
          setWhatsAppGroupAlert(true);
        }
      } catch (error) {
        console.error("Failed to fetch financial data:", error);
        setDepositedMinusExpected('Error fetching financial data');
        setShowAlert(true); // Show alert on error
      }
    };

    const fetchInvoices = async () => {
      try {
        const invoiceResponse = await axios.get(`${baseUrl}/deposits?student_id=${user}`, config);
        const formattedInvoices = invoiceResponse.data.results.map(invoice => ({
          id: invoice.id,
          title: invoice.memo,
          dueDate: formattedDate(invoice.date),
          amount: invoice.amount,
        })).sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate)).slice(0, 2);
        setInvoices(formattedInvoices);
      } catch (error) {
        console.error("Failed to fetch invoices:", error);
      }
    };
    fetchFinancialData();
    fetchInvoices();
  }, [user, token, config]);

  if (depositedMinusExpected === null) {
    return (
        <Box pt={"600px"}>
            <CustomSpinner />
        </Box>
    )
}

  return (
    <Flex direction="column" minH="100vh">
      <Box bg="#00D29D" w="100%" h="8vh" display="flex" alignItems="center" justifyContent="center">
          <Text fontSize="16px" fontWeight="semibold" mt="1vh">Home</Text>
      </Box>
      <Navbar />
      <Box p={5} mt={4}>
        {/* Alert */}
        <Alert status="error" borderRadius="md" mb={4} hidden={!showAlert}>
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Alert!</AlertTitle>
            <AlertDescription display="block">
              You are behind on your payments
            </AlertDescription>
          </Box>
        </Alert>

        <Alert status="success" borderRadius="md" mb={4} hidden={!AppFee}>
          <Box flex="1">
            <AlertDescription display="block">
              You have not paid your application fee, <Text as="span" color="blue.500" cursor="pointer" onClick={onOpen}>click here</Text> to view the information.
            </AlertDescription>
          </Box>
        </Alert>

        <Alert status="success" borderRadius="md" mb={4} hidden={!WhatsAppGroupAlert}>
          <AlertIcon />
          <Box flex="1">
            <AlertDescription display="block">
              Join the Jump Finance WhatsApp Group! <Text as="span" color="blue.500" cursor="pointer" onClick={WhatsAppModalonOpen}>Click Here</Text> to view the information.
            </AlertDescription>
          </Box>
        </Alert>



        {/* Modal */}
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
        <ModalContent>
          <ModalHeader>Application Fee Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              Thank you for sending in your invoice! A welcome video, FAQs, and your contract are available in your student portal at 
              <Text as="span" color="blue.500" fontWeight="bold"> student.jump-finance.com</Text>.
            </Text>

            <Text mb={4}>
              To register for Jump Finance, you must pay a one-time application fee.
            </Text>

            <Text mb={2} fontWeight="bold">Invoice Details:</Text>
            <Box borderWidth="1px" borderRadius="md" p={4} mb={4}>
              <Text><strong>Due Date:</strong> {dueDate}</Text>
              <Text><strong>Application Fee:</strong> {AppFeeValue}</Text>
            </Box>

            <Text mb={4}>
              Payment can be made to the following bank accounts:
            </Text>
            <Box borderWidth="1px" borderRadius="md" p={4} mb={4}>
              <Text><strong>Ecobank Account #:</strong> 6240025074</Text>
              <Text><strong>GTBank Account #:</strong> 201-135803-110</Text>
            </Box>

            <Text mb={4}>
              Upon paying your application fee, you agree to the terms outlined in your student portal.
            </Text>

            <Text fontWeight="bold" color="red.500">
              Important Note: We must receive this payment before clearing you with UTG finance. Please include your MAT number when making your deposit. To reach Jump Finance, please write to: +1 (737) 202-6552
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>Close</Button>
          </ModalFooter>
      </ModalContent>
    </Modal>



    <Modal isOpen={WhatsAppModalisOpen} onClose={WhatsAppModalonClose}>
      <ModalOverlay />
        <ModalContent>
          <ModalHeader>Jump Finance WhatsApp Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
              <Box textAlign='center' mb={4}>
              <Link href={whatsapp_group[universityId - 1]} isExternal>
                <Text as="span" color="blue.500" fontWeight="bold"> WhatsApp Group</Text>
              </Link>
              </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={WhatsAppModalonClose}>Close</Button>
          </ModalFooter>
      </ModalContent>
    </Modal>

       
        <Box textAlign="center">
          <Text fontSize="lg" fontWeight="bold">Amount Behind</Text>
          <Text fontSize="7xl" fontWeight="bold">
            {depositedMinusExpected !== null
              ? `D${depositedMinusExpected < 0 ? `${Math.abs(depositedMinusExpected)}` : 0}`
              : 'Loading...'}
          </Text>
        </Box>
        
        <Flex mt={4} style={{ margin: "0 auto" }} justifyContent="center" alignItems="center" gap="20px" maxW="6xl">
          {/* Modified button */}
          <Button bg="#00D29D" flexGrow={1} size="md" onClick={() => navigate('/payment')}>Payment History</Button>
        </Flex>


        <Divider my={5} />

        <Box mt={5} pb={24}>
          {/* Clock Icon */}
          <Flex align="center" justify="center">
            <TimeIcon boxSize={5} mr={1} mb={3}/>
            <Heading size="md" mb={3}>Recent Payments</Heading>
          </Flex>
          <SimpleGrid columns={1} spacing={4} justifyItems="center" width="full">
            {invoices.map((invoice) => (
              <PaymentCard
                key={invoice.id}
                title={invoice.title}
                dueDate={invoice.dueDate}
                amount={invoice.amount}
                transactionType="Deposit"
              />
            ))}
          </SimpleGrid>
        </Box>
      </Box>
    </Flex>
  );
}

export default Home;
